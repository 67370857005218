<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="app flex-row align-items-center">
        <div class="container">
          <h1 class="mb-2 text-center">
            Ваша электронная почта успешно верифицирована.
          </h1>
          <p class="mb-5 text-center">
            Введите пароль для последующих авторизаций
          </p>
          <b-row class="justify-content-center">
            <b-col
              lg="4"
              md="6"
              sm="8"
            >
              <b-form @submit.prevent="onSubmit">
                <b-form-group
                  id="emailInputGroup1"
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    name="email"
                    :state="chkState('email')"
                    aria-describedby="input1LiveFeedback1"
                    placeholder="Email"
                    autocomplete="email"
                    disabled
                    autofocus
                  />
                  <b-form-invalid-feedback
                    id="input1LiveFeedback1"
                  >
                    Это поле обязательно для заполнения, так же адрес электронной почты должен быть действительным
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="passInputGroup2"
                  label="Пароль"
                  label-for="raw_password"
                >
                  <b-form-input
                    id="raw_password"
                    v-model.trim="$v.form.rawPassword.$model"
                    type="password"
                    name="raw_password"
                    :state="chkState('rawPassword')"
                    aria-describedby="input1LiveFeedback2"
                    placeholder="Пароль"
                  />
                  <b-form-invalid-feedback
                    id="input1LiveFeedback2"
                  >
                    Это поле обязательно для заполнения, пароль должен содержать: цифры, прописные и строчные буквы, минимум 8 символов
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="passInputGroup3"
                  label="Подтверждение пароля"
                  label-for="repeat_password"
                >
                  <b-form-input
                    id="repeat_password"
                    v-model.trim="$v.form.repeatPassword.$model"
                    type="password"
                    name="repeat_password"
                    :state="chkState('repeatPassword')"
                    aria-describedby="input1LiveFeedback3"
                    placeholder="Подтверждение пароля"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback3">
                    Пароли должны совпадать
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="loading"
                  :class="{'loading' : loading}"
                >
                  Войти
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {activationInvitation} from '../../services/api';
import {validationMixin} from 'vuelidate';
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';

const strongPass = helpers.regex(
  'strongPass',
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
);

export default {
  name: 'ConfirmInvite',
  mixins: [validationMixin, notifications],
  data() {
    return {
      token: {
        activation: this.$route.query.activation,
      },
      form: {
        email: '',
        rawPassword: '',
        repeatPassword: '',
        activation: '',
      },
      loading: true,
      success: false,
    };
  },
  computed: {
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      rawPassword: {
        required,
        minLength: minLength(8),
        strongPass,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('rawPassword'),
      },
    },
  },
  mounted() {
    const parsedData = this.parseJwt(this.token.activation);
    this.form.email = parsedData.email;
    this.form.activation = this.token.activation;
    this.loading = false;
  },
  methods: {
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else return !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await activationInvitation(this.form);
        if (response && response.status === 200) {
          this.showSuccess('Аккаунт подтвержден, пароль создан');
          this.$router.push({path: '/'});
        }
        this.loading = false;
      }
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

